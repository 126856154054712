.about-container {
  padding-top: 200px;
  line-height: 0.9;
  padding-bottom: 150px;
  margin-left: 5rem;
  margin-right: 5rem;
}
.about-container h1 {
  text-align: left;
  font-size: clamp(50px, 6vw, 100vw);
  color: #fff;
  font-family: Cafe24Dangdanghae;
}
.about-container img {
  max-width: 100%;
}
.about-intro-container {
  margin-top: 9vw;
}
.about-container .about-intro {
  line-height: 1.3;
  font-size: clamp(20px, 3vw, 100vw);
  max-width: 65%;
  margin-left: auto;
  font-weight: 400;
  color: #fdfaf4;
  text-align: start;
  font-family: "Noto Serif KR", serif;
}
.mini-intro {
  margin-top: 70px;
  margin-left: auto;
  margin-bottom: 50px;
}
.mini-intro p {
  color: #fdfaf4;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  font-size: clamp(15px, 1.2vw, 100vw);
  font-family: "Noto Sans KR", sans-serif;
}

@media (max-width: 767px) {
  .about-container {
    /* padding-top: 130px; */
    padding-bottom: 130px;
  }
  .about-container .about-intro {
    max-width: 100%;
  }
  .about-container img {
    max-width: 70%;
    margin-bottom: 2rem;
  }
  .mini-intro {
    margin-top: 40px;
    margin-left: auto;
    margin-bottom: 50px;
  }
}
