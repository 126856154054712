.birthday-col {
  align-content: center;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  padding: 5px;
}

.react-datepicker__view-calendar-icon input {
  padding: 5px 0px 5px 35px !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.7rem !important;
}
