.tarot-banner {
  position: relative;
  padding-top: 85px;
  padding-bottom: 66px;
  color: #fff;
  text-align: start;
}

.tarot-banner h2 {
  text-transform: uppercase;
  color: #ff5000;
  line-height: 1.33;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.tarot-banner .title {
  font-size: 3.5rem;
  font-family: "Cafe24Dangdanghae";
  letter-spacing: 0.2rem;
  margin-bottom: 52px;
}

.tarot-banner .header {
  text-align: center;
}

.tarot-banner .card {
  background-color: transparent;
  display: flex; /* Ensures that card contents use flexbox */
  flex-direction: column; /* Stacks card content vertically */
  justify-content: center; /* Centers card content */
}
.tarot-banner .tarot-card {
  width: 100%; /* Ensures image takes up all available width */
  height: auto; /* Keeps the aspect ratio of the image */
  object-fit: cover; /* Ensures the image covers the card area */
  margin-bottom: 0.5rem;
}

.tarot-banner .card-title {
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  margin-bottom: 1rem;
}

.tarot-banner .card-body {
  text-align: center;
}
.tarot-banner .tarot-card {
  margin-bottom: 0.5rem;
}
.tarot-banner .tarot-card:hover {
  box-shadow: 0px 0px 9px 4px #d3a121;
}

@keyframes glow {
  to {
    box-shadow: 0px 0px 30px 20px #d3a121;
  }
}

.tarot-banner .card-text {
  color: #fff;
  font-family: "Noto Serif KR", serif;
}

@media (max-width: 575.98px) { 
  .tarot-banner .card {
    margin: 0; /* Remove any margin that could be causing issues */
    padding: 0; /* Remove padding if necessary */
  }

  .tarot-banner .card-text {
    font-size: 14px;
  }
  .tarot-card-img-container  {
    display: flex;
    justify-content: center;
  }
  .tarot-banner .tarot-card {
    width: 60%;
  }
  .tarot-banner .title {
    font-size: 2.5rem;
  }
}
