.profile-tabs .nav-link {
  color: #faf0e6;
  opacity: 0.7;
  font-family: "Noto Sans KR", sans-serif;
}
.profile-tabs .nav-link.active,
.profile-tabs .nav-link:hover,
.profile-tabs .nav-link:focus {
  color: #fff !important;
}

.profile-post-edit {
  display: flex;
  justify-content: center;
}

.profile-post-container {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
}
.table-title-container {
  padding: 0 !important;
  color: #fff !important;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
}