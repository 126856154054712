.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center; */
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-text {
  /* display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center; */
  
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: -webkit-center;
  color: #fff;
}

.mytypist {
  font-size: 3.5rem;
  font-weight: 800;
  color: rgb(253, 250, 244);
  font-family: "Cafe24Dangdanghae";
  letter-spacing: 0.2rem;
}

.Typist span {
  display: inline !important;
}

.hero-text .none {
  display: none;
}

.hero-text .show {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-text .arrow-icon {
  font-size: 2.5rem;
}
.Typist .Cursor--blinking {
  animation: none !important;
}
.arrow-contact {
  font-size: 2.5rem;
}
@media (max-width: 690px) {
  .mytypist {
    font-size: 2.5rem;
  }
  .arrow-contact {
    font-size: 1.5rem;
  }
}
