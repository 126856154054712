.user-profile-mini-container h5 {
  font-family: HSBombaram3_Regular;
  margin-bottom: 1rem;
}
.user-profile-mini-container h6 {
  font-family: "Noto Sans KR", sans-serif;
  margin-bottom: 0;
}
.user-profile-mini-container p {
  font-family: "Noto Serif KR", serif;
  font-size: 14px;
}
