.comment-container .card {
  background-color: oklch(20.8% 0.012 266);
}

.vh-100 {
  min-height: 100vh;
}

.comment-row {
  margin-bottom: 1rem;
}

.comment-avatar {
  width: 50px;
  height: 50px;
}

.comment-body {
  padding-left: 1rem;
  color: #9ca8b4;
}

.comment-author {
  font-weight: bold;
  color: #9ca8b4;
}

.comment-time {
  font-size: 0.8rem;
  color: #8c8c8c;
  margin-left: 0.5rem;
}

.reply-link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.comment-container .btn-link {
  text-decoration: none;
  color: #fff;
}

.comment-container .btn-link:hover {
  text-decoration: underline;
  color: #fff;
}

.reply-icon {
  margin-left: 0.5rem;
}

.comment-content {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #9ca8b4;
}

.comment-container textarea.form-control {
  resize: none;
}

.comment-title {
  color: #f2f5f7;
}

.comment-button {
  color: white;
  text-decoration: none;
  font-size: 15px;
  border: none;
  background: none;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.comment-button::before {
  margin-left: auto;
}

.comment-button::after,
.comment-button::before {
  content: "";
  width: 0%;
  height: 2px;
  background: #ff5000;
  display: block;
  transition: 0.5s;
}

.comment-button:hover::after,
.comment-button:hover::before {
  width: 100%;
}

.comment-container .dotBtn {
  color: #9ca8b4;
  background-color: transparent;
}
.comment-container .dotBtn Button {
  border: none;
  color: #9ca8b4;
  background-color: transparent;
}
.comment-container .dotBtn Button:active {
  background-color: transparent;
}
.comment-container .dotBtn Button:focus-visible {
  box-shadow: none;
}
.comment-container .dotBtn .dropdown-toggle::after {
  content: none;
}
