.pagination-outer {
  display: inline-block;
  text-align: center;
  margin-bottom: 5rem;
}

.pagination {
  font-family: "Noto Sans KR", sans-serif;
  display: inline-flex;
  position: relative;
  text-align: center;
}

.pagination .page-link {
  color: #555;
  background: transparent;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0 7px;
  border-radius: 0;
  display: block;
  position: relative;
  z-index: 0;
  transition: all 0.4s ease 0s;
}

.pagination .page-link:first-child,
.pagination .page-link:last-child {
  font-size: 20px;
}

.pagination .page-link:hover,
.pagination .page-link:focus,
.pagination .active .page-link:hover,
.pagination .active .page-link {
  color: #fff;
  border-color: #fff;
}

.pagination .page-link:before {
  content: "";
  background-color: #cc4100;
  opacity: 0;
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: -2;
  transition: all 1s ease 0s;
}

.pagination .page-link:hover:before,
.pagination .page-link:focus:before,
.pagination .active .page-link:hover:before,
.pagination .active .page-link:before {
  opacity: 1;
}
.pagination .page-link:focus {
  box-shadow: none;
}
