.contactpage-container {
  padding-top: 200px;
  padding-bottom: 150px;
  width: calc(100% - 4.16vw);
}

.contactpage-inner-container {
  display: flex;
  -ms-justify-content: space-between;
  justify-content: space-around;
  /* justify-content: space-between; */
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.contactpage-inner-container h1 {
  font-family: Cafe24Dangdanghae;
  line-height: 0.8;
  font-size: clamp(50px, 6vw, 100vw);
  margin-bottom: 75px;
  display: block;
  color: #fff;
}
.contact-overlay-container {
  position: relative;
  width: 40%;
}
.arrow-contact {
  /* position: absolute;
  top: 21%;
  left: 23%;
  transform: translate(-50%, -50%);
  color: #fff; */
  color: #fff;
  margin-left: 50px;
  font-size: 1.5rem;
}

@media (max-width: 703px) {
  .contactpage-container  {
    padding-top: 115px;
    padding-bottom: 130px;
  }
  .contactpage-inner-container {
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  }
  .contactpage-inner-container h1 {
    margin-bottom: 0;
    margin-top: 68px;
  }
  .contactpage-container {
    width: 100%;
  }
  .arrow-contact {
    margin-left: 0;
    margin-top: 2rem;
  }
  .contact-overlay-container {
    width: 70%;
  }
}
