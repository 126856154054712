.hero-sm {
  width: 100%;
  position: relative;
}

.selected-card-page {
  margin-top: 11rem;
  padding: 162px 0 180px 0;
  text-align: left;
}

.selected-card-page .card {
  width: 230px;
  height: 350px;
  background: transparent;
  animation: fadeIn;
  animation-duration: 2.5s;
}

.selected-card-box {
  display: flex;
  justify-content: center;
}
.selected-title {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.selected-title h1 {
  font-size: 2.5rem;
  font-family: "Cafe24Dangdanghae";
  margin-bottom: 2rem;
  text-shadow: 0 2px 5px #c4b59d, 0px -0px 1px #fff;
  color: #f1ebe5;
}

.selected-card-page h2 {
  font-family: HSBombaram3_Regular;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgb(204, 176, 100);
  animation: fadeIn;
  animation-duration: 3s;
  margin-bottom: 2rem;
}

.fortune {
  border-left: 5px solid;
  margin-left: 0;
  padding: 1rem 0 1rem 2rem;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    35deg,
    /* hsl(332, 91%, 78%), */ /* hsl(161, 71%, 93%) */ white,
    white
  );
  /* background-image: white; */
  color: hsl(310, 100%, 95%);
  animation: fadeIn;
  animation-duration: 3s;
}

.selected-card-page p {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 1.2px;
  color: white;
  font-family: "Noto Serif KR", serif;
  margin-bottom: 1.4rem;
}

.icon {
  font-size: 1.1rem;
  margin-right: 1rem;
  color: rgb(182, 223, 144);
}

.keyword {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: "Cafe24ClassicType-Regular";
  transition: 700ms ease;
  font-variation-settings: "wght" 400;
  color: palegoldenrod;
  padding: 1rem;
}

.keyword:hover {
  font-variation-settings: "wght" 600;
  letter-spacing: 1px;
}

.keywords {
  margin-top: 3rem;
  animation: fadeIn;
  animation-duration: 3s;
  display: flex;
  flex-wrap: wrap;
}

.keywords-affirm {
  animation: fadeIn;
  animation-duration: 3s;
}

.border-box {
  padding: 3rem;
  border-image: linear-gradient(
      rgba(255, 215, 137, 0.5),
      rgba(255, 255, 255, 0.5)
    )
    50;

  border-width: 4px;
  border-style: solid;
  padding-bottom: 5rem;
  padding-top: 5rem;
  animation: fadeIn;
  animation-duration: 3s;
}

.hero-button-sm {
  text-align: center;
  padding-bottom: 5rem;
}

.affirm {
  font-family: "EB Garamond", serif !important;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  font-kerning: normal;
  color: #f4fff3 !important;
  letter-spacing: 0.03em !important;
  padding: 2rem;
  font-size: 1.2rem !important;
  animation: fadeIn;
  animation-duration: 3s;
}

.affirm::before {
  content: "“";
  margin-right: 0.5rem;
}

.affirm::after {
  content: "”";
  margin-left: 0.5rem;
}

.backBtn {
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1199px) {
  .selected-card-box {
    margin-bottom: 5rem;
  }

  .backBtn {
    top: 92%;
  }
}
@media (max-width: 768px) {
  .selected-card-page .card {
    width: 200px;
  height: 300px;
    /* width: 100%; */
    /* max-width: 230px; */
    /* height: auto; */
  }

  .selected-title h1 {
    font-size: 2rem;
  }

  .backBtn {
    top: 94%;
  }
  .border-box {
    padding-bottom: 0;
    border: none;
  }
  .selected-card-page {
    padding-top: 7rem;
  }
}
@media (max-width: 425px) {
  .selected-card-page p {
    font-size: 1rem;
  }
  .selected-card-page {
    padding-top: 5rem;
    margin-top: 7rem
  }
}