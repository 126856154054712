.forum-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
  margin-bottom: 3rem;
}

.forum-sm {
  background: oklch(20.8% 0.012 266);
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  max-width: 60rem;
}

.forum-sm:hover {
  border: 1px solid #ffd11a;
}

.forum-sm .dotBtn {
  position: absolute;
  right: 0;
  top: 90%;
  transform: translateY(-50%);
  color: #9ca8b4;
  background-color: transparent;
}
.forum-sm .dotBtn Button {
  border: none;
  color: #9ca8b4;
  background-color: transparent;
}
.forum-sm .dotBtn Button:active {
  background-color: transparent;
}
.forum-sm .dotBtn Button:focus-visible {
  box-shadow: none;
}
.forum-sm .dotBtn .dropdown-toggle::after {
  content: none;
}

.forum-summary {
  min-width: 540px;
  position: relative;
}
.forum-options-sm {
  position: absolute;
  right: 5%;
  top: 90%;
  transform: translateY(-50%);
  color: #9ca8b4;
}

.forum-reactions-sm {
  display: flex;
  align-items: center;
}

.user-photo-sm {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.forum-details {
  flex-grow: 1;
}

.forum-author-sm {
  font-size: 0.9rem;
  color: #d9e0e8;
  margin: 0;
}

.forum-date-sm {
  font-size: 0.8rem;
  color: #9ca8b4;
}

.forum-title-sm {
  font-size: 1.2rem;
  color: hsl(210deg, 25%, 96%);
  margin: 5px 0;
  text-align: start;
}

.forum-actions-sm {
  display: flex;
  align-items: center;
  gap: 10px;
}

.forum-icon-sm {
  cursor: pointer;
  font-size: 1rem;
  color: #d9e0e8;
}

.forum-comment-icon-sm {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
}

.forum-summary .dropdown-item:active {
  background-color: transparent;
}

@media (max-width: 650px) {
  .forum-summary {
    min-width: 300px;
  }
}
