.underlined-menu {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center left;
  color: white;
  background-color: transparent;

  .wrapper {
    display: flex;
    justify-content: space-evenly;
  }

  .menu-item {
    margin: 0 1.1rem;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    font-family: "Noto Sans KR", sans-serif;
    &:first-child {
      margin-left: 3rem;
    }
    &:last-child {
      margin-right: 3rem;
    }
  }
  .menu-item:hover {
    color: white;
  }
  .underline {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 15px;
    background: white;
    opacity: 0.85;
  }
}

.tarot-master-container {
  padding-left: 10rem;
  padding-right: 10rem;
}

@media (max-width: 1100px) {
  .tarot-master-container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (max-width: 741px) {
  .tarot-master-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .menu-item {
    font-size: 1rem !important;
    margin: 0 0.7rem !important;
    &:first-child {
      margin-left: 3rem !important;
    }
  }
  .underline {
    height: 2px !important;
  }
}

@media (max-width: 601px) {
  .tarot-master-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .menu-item {
    font-size: 0.9rem !important;
    margin: 0 0.5rem !important;
    &:first-child {
      margin-left: 2rem !important;
    }
  }
  .underline {
    height: 2px !important;
  }
}
