.user-profile-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  align-content: center;
}
.user-image-container {
  display: inline-block;
  text-align: center;
}
.user-image {
  background-color: whitesmoke;
  width: 60%;
  aspect-ratio: 1 / 1;
  /* height: 90%; */
  object-fit: cover;
}

.profile-upload-button {
  border: none;
  background-color: transparent;
  font-size: 1.7rem;
  margin-top: -2rem;
}
.profile-label {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 800;
}

.selected-button {
  text-decoration: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-family: "Noto Sans KR", sans-serif;
  border: none;
  font-size: 14px;
}

.selected-button:hover {
  color: rgba(0, 0, 0, 1);
}

.user-profile-box {
  font-family: "Noto Serif KR", serif;
  font-size: 14px;
}

.profile-input-box {
  resize: none;
  font-size: 14px;
  font-family: "Noto Serif KR", serif;
}

.custom-toast {
  margin-top: 7rem;
}

.remove-user {
  background-color: transparent;
  border: none;
  color: #f51515;
}
.user-profile-page-first-row {
  background-color: linen;
  border-radius: 20px;
  padding: 2rem;
  margin-top: 1rem;
}
.user-profile-page-second-row {
  background-color: linen;
  border-radius: 20px;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

@media (min-width:1200px) {
  .user-profile-page-first-row,
  .user-profile-page-second-row {
    margin: 2rem;
  }
}