.new-post-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6rem;
}

.new-post-btn {
  background: #ff4742;
  border: 1px solid #ff4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: right;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.new-post-btn:hover,
.new-post-btn:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.new-post-btn:active {
  opacity: 0.5;
}
