.navbar {
  font-family: "Noto Serif KR", serif;
  font-size: 1.2rem;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  position: fixed !important;
  padding: 15px 10px;
  background-color: #231f20;
}

.navbar.scrolled {
  padding: 0px 0;
  background-color: black;
}

.brand-text {
  color: white;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  font-family: "Cafe24ClassicType-Regular";
}

.navbar-nav .nav-link {
  font-weight: 400;
  color: rgba(250, 250, 250, 0.6);
  letter-spacing: 0.8px;
  white-space: nowrap;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  position: relative;
  font-size: 17px;
}

.navbar-nav .nav-link.active, 
.navbar-nav .nav-link.show {
  color: #fff !important;
}

.navbar-nav .profile-icon {
  margin-right: 3rem
}

.navbar-container .navbar-nav .nav-link:visited,
.navbar-container .navbar-nav .nav-link:hover,
.navbar-container .navbar-nav .nav-link:focus {
  color: #fff;
}

nav #tarot-nav-dropdown,
nav #study-nav-dropdown,
nav #admin-nav-dropdown {
  color: rgba(250, 250, 250, 0.6);
}
nav #tarot-nav-dropdown .nav-link:focus,
nav #tarot-nav-dropdown .nav-link:hover,
nav #tarot-nav-dropdown .nav-link:active,
nav #study-nav-dropdown .nav-link:focus,
nav #study-nav-dropdown .nav-link:hover,
nav #study-nav-dropdown .nav-link:active,
nav #admin-nav-dropdown .nav-link:focus,
nav #admin-nav-dropdown .nav-link:hover,
nav #admin-nav-dropdown .nav-link:active {
  color: #fff !important;
}

nav #tarot-nav-dropdown:focus .dropdown-toggle,
nav #tarot-nav-dropdown:hover .dropdown-toggle,
nav #tarot-nav-dropdown:active .dropdown-toggle,
nav #study-nav-dropdown:focus .dropdown-toggle,
nav #study-nav-dropdown:hover .dropdown-toggle,
nav #study-nav-dropdown:active .dropdown-toggle,
nav #admin-nav-dropdown:focus .dropdown-toggle,
nav #admin-nav-dropdown:hover .dropdown-toggle,
nav #admin-nav-dropdown:active .dropdown-toggle {
  color: #fff !important;
}

.navbar-nav .dropdown-menu {
  background-color: #1e1e1e;
  text-align: center;
  padding: 0.7rem;
}
nav .dropdown-item:focus,
nav .dropdown-item:hover,
nav .dropdown-item:active {
  background-color: transparent !important;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-dropdown-item {
  animation: fadeIn 0.3s ease-in-out;
  padding: 0.7rem !important;
}
.nav-dropdown-item2 {
  animation: fadeIn 0.5s ease-in-out;
  padding: 0.7rem !important;
}
.nav-dropdown-item3 {
  animation: fadeIn 0.7s ease-in-out;
  padding: 0.7rem !important;
}
.nav-dropdown-item4 {
  animation: fadeIn 0.9s ease-in-out;
  padding: 0.7rem !important;
}

nav .navbar-toggler {
  border: none;
  transition: all 0.5s ease-in-out;
  color: #fff;
  font-size: 1.7rem;
}

nav .navbar-toggler:active,
nav .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav .searchbox {
  color: #d7cdc3;
  font-family: "Noto Sans KR", sans-serif;
  font-style: italic;
  font-size: 13px;
  border-bottom-style: groove !important;
  border: none;
  border-radius: 1px;
  background-color: transparent;
  font-size: 16px;
}

nav .searchbutton {
  font-family: "Noto Sans KR", sans-serif;
  font-style: italic;
  font-size: 18px;
  background-color: transparent;
  color: #ffd11a;
  border: none;
  padding: 1px;
  margin-right: 10px;
}

nav .searchbutton:hover {
  color: #d7cdc3;
  background-color: transparent;
}
nav .searchbutton:active {
  background-color: transparent !important;
}

nav .searchbar .form-control {
  color: #d7cdc3;
  border-width: 2px;
  background-color: transparent;
}
nav .searchbar .form-control::placeholder {
  color: #d7cdc3;
}
nav .searchbar .form-control:focus {
  box-shadow: none;
  color: #d7cdc3;
  border: none;
  background-color: transparent;
}

@media (max-width: 991.98px) {
  .navbar-nav .profile-icon {
    margin-right: 0;
  }
  .navbar-nav .nav-link {
    text-align: center;
    margin: 0 !important;
  }

  .navbar-collapse {
    padding-bottom: 2rem;
  }
  nav .searchbar {
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  nav .searchbar .form-control {
    width: 40%;
  }
  nav .searchbutton {
    margin-right: 0;
  }
}

@media (max-width: 575.98px) { 
  .navbar {
    padding: 0px 0 !important;
  }
  .navbar-logo {
    width: 80px
  }
}
