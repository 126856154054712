.about-banner {
  position: relative;
  padding-top: 115px;
  padding-bottom: 130px;
  background-color: #fff;
  text-align: start;
}

.about-banner h2 {
  text-transform: uppercase;
  color: #ff5000;
  line-height: 1.33;
  font-size: 1rem;
}

.about-banner p {
  color: #231f20;
  font-family: "Noto Serif KR", serif;
  line-height: 2rem;
}

.about-banner .title {
  font-family: "Noto Sans KR", sans-serif;
  margin-bottom: 10px;
}

.about-banner img {
  width: 30%;
  margin-left: -20px;
}

@media (max-width: 575.98px) { 
  
.about-banner img {
  width: 30%;
  margin-left: 0;
}
}
