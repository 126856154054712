.contact-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  /* width: 40%; */
  margin-left: 50px;
}

.contact-form {
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #674736;
  font-family: "IBM Plex Serif", serif;
}

form .form-control {
  border-width: 1px;
  color: #674736;
}

form .form-control:focus {
  box-shadow: none;
  border: 1px solid rgb(156, 116, 71, 0.8);
}

.contactBtn {
  background-color: #ff5000;
  padding: 0.5rem 2rem;
  border-radius: 6px;
  color: white;
  font-size: 1.1rem;
  font-weight: 800;
  letter-spacing: 2px;
  border: none;
  margin-top: 2rem;
  margin-bottom: 5rem;
  font-family: "Jost", sans-serif;
  transition: all 0.2s ease-out;
}
.contactBtn:hover {
  color: #ff5000;
  background-color: #fff;
  background-position: 0 0;
}

p.success {
  color: rgb(12, 114, 12);
  margin-top: 34px;
  margin-bottom: -20px;
  margin-left: 12px;
  font-size: 18px;
}

p.danger {
  color: rgb(168, 60, 60);
  margin-top: 34px;
  margin-bottom: -20px;
  margin-left: 12px;
  font-size: 18px;
}

@media (max-width: 703px) {
  .contact-container {
    /* width: 50%; */
    margin-left: 0;
  }
  .contactBtn {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
}
