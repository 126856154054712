.read-only-quill .ql-container {
  height: auto !important;
  background-color: #121212;
  color: #fff;
}

.read-only-quill .ql-editor {
  height: auto !important;
}

.read-only-quill p {
  color: #fff;
  line-height: 2;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-family: "Noto Serif KR", serif;
}
.read-only-quill img {
  object-fit: cover;
  border-radius: 0.5rem;
  max-height: 400px;
  display: block;
  vertical-align: middle;
  width: 80%;
}

.read-only-quill h1,
.read-only-quill h2,
.read-only-quill h3,
.read-only-quill h4,
.read-only-quill h5,
.read-only-quill h6 {
  color: #d9e0d9;
  margin-bottom: 12px !important;
  font-family: "Noto Sans KR", sans-serif;
}

.read-only-quill h2 {
  margin-bottom: 12px !important;
  margin-top: 64px !important;
}

.read-only-quill :has(> img) {
  display: flex;
  justify-content: center;
}
