.App {
  text-align: center;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: bottom;
  z-index: 9999;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: top;
  z-index: 9999;
}