.footer {
  padding: 3rem 4rem;
  background-color: #231f20;
  color: white;
}

.footer h4 {
  font-family: "Cafe24ClassicType-Regular";
  font-size: 16px;
  margin-bottom: 2rem;
}

.footer h5 {
  font-size: 15px;
  font-weight: 600;
}

.footer a {
  text-decoration: none;
  color: white;
  padding-bottom: 0.4rem;
  font-size: 15px;
}

.footer p {
  font-size: 15px;
}

.footer hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 348px) {
  .footer {
    padding: 3rem 2rem;
  }
  .footer h5 {
    margin-top: 1rem;
  }
}
