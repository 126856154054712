.new-password-container .form-control {
  color: #6b5844;
  border-width: 2px;
  font-family: "Noto Serif KR", serif;
  font-size: 14px;
}
.new-password-container .form-control:focus {
  box-shadow: none;
  color: #6b5844;
  border: 2px solid rgba(159, 104, 78, 0.5);
}

.new-password-container .form-label {
  font-family: "Noto Sans KR", sans-serif;
}

.new-password-btn {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin-top: 1rem;
  margin: 0;
  padding: calc(0.675rem - 1px) calc(1.1rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.new-password-btn:hover,
.new-password-btn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.new-password-btn:hover {
  transform: translateY(-1px);
}

.new-password-btn:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
