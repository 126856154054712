.upload-button {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center center;
  margin-bottom: 100px;
  font-family: "Noto Sans KR", sans-serif;

  .wrapper {
    position: relative;
    height: 3rem;
    width: 8rem;
    overflow: hidden;
    cursor: pointer;
    .container {
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1rem;
      padding: 0 2rem;
      overflow: hidden;
      &.upload {
        background: white;
        color: #eb417b;
        z-index: 1;
        top: 0;
      }
      &.loading {
        background: #f0759e;
        color: white;
        z-index: 2;
        top: -100%;
        position: relative;
        .upload-loader {
          height: 1.5rem;
          width: 1.5rem;
          border: transparent solid 4px;
          border-top: white solid 4px;
          border-radius: 50%;
          animation: spin 0.7s linear infinite;
        }
        .loading-bar {
          position: absolute;
          top: calc(100% - 3px);
          left: 0;
          width: 0%;
          height: 3px;
          background: white;
        }
      }
      &.done {
        background: #eb417b;
        color: white;
        z-index: 3;
        top: -100%;
      }
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
