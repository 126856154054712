.page-header {
  padding-top: 210px;
  padding-bottom: 80px;
  text-align: center;
}

.page-header .title {
  color: #fff;
  padding-bottom: 30px;
  font-family: "Cafe24Dangdanghae";
}

.page-header .subtitle {
  color: #fff;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: 2;
  font-family: "Noto Serif KR", serif;
}

.alert-box {
  font-family: "Noto Sans KR", sans-serif;
  background-color: rgba(255, 80, 0, 0.5) !important;
  color: #fff !important;
  margin-top: 20px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 31%;
  animation: fadeIn 0.5s ease-in-out;
}

.alert-icon {
  font-size: 1.5rem;
}

@media (max-width: 1122px) {
  .alert-box {
    width: 50%;
    left: 25%;
  }
}

@media (max-width: 991.98px) {
  .page-header .subtitle {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .alert-box {
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 500px) {
  .page-header .subtitle {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 13px;
  }
  .alert-box {
    font-size: 13px;
  }
}
