.blog-full-container {
  padding-top: 210px;
  padding-bottom: 80px;
  padding-left: 10rem;
  padding-right: 10rem;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  text-align: start;
}

.blog-full-container .goBackBtn {
  color: #9ca8b4;
  background-color: transparent;
  border: none;
  padding-left: 0;
}

.blog-full-container .goBackBtn:focus-visible {
  box-shadow: none;
}

.blog-full-container .goBackBtn:active {
  background-color: transparent !important;
}

.blog-full-container .goBackBtn:hover {
  background-color: transparent;
}

.blog-content-container {
  padding-top: 48px;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  max-width: 1100px;
}

.blog-full-category {
  background-color: rgb(44, 44, 44);
  color: rgba(255, 255, 255, 0.75);
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}

.blog-full-header {
  padding-top: 48px;
  padding-bottom: 36px;
}

.blog-full-title {
  color: #f2f5f7;
  margin-top: 24px;
  margin-bottom: 16px;
}

.blog-full-abstract {
  color: #9ca8b4;
}

.blog-full-date {
  color: rgb(140 140 140/1);
  font-size: 0.875rem;
  opacity: 0.75;
}

.blog-full-img-container {
  display: flex;
  justify-content: center;
}

.blog-full-img {
  object-fit: cover;
  border-radius: 0.5rem;
  max-height: 300px;
  display: block;
  vertical-align: middle;
  width: 80%;
}

.post-progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #ff5000;
  transform-origin: 0%;
  z-index: 9999;
}
