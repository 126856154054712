.my-flex {
  display: flex;
  align-items: center;
}

.my-grid {
  display: grid;
  align-items: center;
  gap: 1rem;
}

.error {
  color: #f51515;
  text-align: left;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: -7px;
}

.loginBtn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  padding: 0.65rem 0rem;
  color: #0a0a0b;
  background: #d5d3d0;
  justify-content: center;
  font-family: "Noto Serif KR", serif;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin-top: 1rem;
}

.loginBtn .loginIcon {
  margin-left: 0.5rem;
  font-size: large;
  cursor: pointer;
}

.loginBtn:hover {
  color: #e5e4e2;
  background: #0a0a0b;

  .loginIcon {
    transform: translateX(5px);
    transition: 0.3s ease;
  }
}

.loginPage {
  min-height: 100vh;
  width: 100%;
  margin: auto;
  margin-top: 100px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 7px 50px rgb(214, 223, 213);
}

.loginContainer {
  min-height: calc(100vh - 100px);
  width: 65%;
  margin: auto;
  justify-content: space-between;
  border-radius: 10px;
  background: #e0e0e2;
}
.registerContainer {
  min-height: calc(100vh - 100px);
  width: 65%;
  margin: auto;
  justify-content: space-between;
  border-radius: 10px;
  background: #e0e0e2;
}

.videoDiv {
  position: relative;
  display: flex;
  flex-basis: 50%;
  width: 70%;
  /* height: 100%; */
  min-height: calc(100vh - 100px);
  padding: 0 1.5rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}

video,
.loginFooterDiv {
  position: absolute;
}

video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.loginTextDiv {
  position: relative;
  align-items: center;
}

.loginTextDiv .loginTitle {
  color: white;
  font-size: 35px;
  font-family: "HSBombaram3_Regular";
}

.loginTextDiv .loginText {
  color: white;
  padding: 1rem;
  font-weight: 600;
  font-family: "Noto Serif KR", serif;
  font-size: 19px;
}

.loginFooterDiv {
  bottom: 10px;
  padding: 0 1rem;
  height: 60px;
  background: rgba(255, 255, 255, 0.248);
  left: 10px;
  right: 10px;
  justify-content: space-between;
  backdrop-filter: blur(1px);
  border-radius: 10px;
}

.loginFooterDiv .text {
  color: white;
  font-family: "Noto Sans KR", sans-serif;
}

.loginFooterDiv .loginBtn {
  background: #e5e4e2;
  color: #0a0a0b;
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  font-weight: 600;
  margin-top: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #0a0a0b;
    color: #e5e4e2;
  }
}

.loginFormDiv {
  flex-basis: 50%;
  margin: auto;
  flex-direction: column;
  gap: 1.5rem;
  transform: translateY(-1rem);
}
.registerFormDiv {
  flex-basis: 50%;
  margin: auto;
  flex-direction: column;
  /* transform: translateY(-1rem); */
}

.loginHeaderDiv {
  text-align: center;
  padding: 1.5rem 0;
  margin-top: -30px;
  letter-spacing: 1.3px;
  font-family: "Cafe24Dangdanghae";
}

.loginHeaderDiv img {
  width: 200px;
  margin-bottom: -50px;
}

.loginHeaderDiv h3 {
  font-size: 25px;
  color: black;
  font-weight: 600;
  margin: 11px 0 -20px;
}

.loginForm {
  gap: 1rem;
}
.registerForm {
  gap: 0;
}

.loginForm .loginInputDiv,
.registerForm .loginInputDiv {
  .loginIcon {
    color: #9c634f;
  }

  .loginInput {
    gap: 0.5rem;
    padding: 0.5rem;
    background: #e0e0e2;
    border-radius: 10px;
    /* font-size: 16px; */
  }
}

.loginInputDiv input {
  background: none;
  outline: none;
  border: none;
  width: 81%;
  font-size: 13px;
  /* font-size: 16px; */
  font-family: "Noto Sans KR", sans-serif;
  border-bottom-style: groove;
}

.loginInputDiv label {
  color: #000;
  font-weight: 600;
  font-size: 12px;
  /* padding: 0.5rem 0; */
  padding-top: 0.5rem;

  display: block;
  font-family: "Noto Serif KR", serif;
  text-align: start !important;
}

.forgotPassword {
  font-size: 13px;
  color: black;
  opacity: 0.7;
  text-align: center;
}
.forgotPassword a {
  text-decoration: none;
}

.socialLogin {
  font-size: 13px;
  color: black;
  opacity: 0.7;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
}

.socialLoginButton {
  /* padding-top: 1rem; */
  font-size: 1.2rem;
}

.socialLoginButton button {
  padding: 0.5rem 2rem;
  background-color: transparent;
  border: none;
}

.pw-width {
  width: 60%;
}

.showInSmall {
  display: none;
}

.send-verification-btn {
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.send-verification-btn:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.send-verification-btn:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}

.send-verification-btn:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.send-verification-btn:focus {
  outline: 1px transparent;
}

.send-verification-btn:before {
  display: none;
}

.send-verification-btn:-webkit-details-marker {
  display: none;
}

@media (max-width: 991.98px) {
  .registerContainer {
    /* min-height: calc(100vh - 100px); */
    height: auto;
    padding-top: 3rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-bottom: 3rem;
  }
  .loginContainer {
    /* min-height: calc(100vh - 100px); */

  }
  .loginFooterDiv .loginBtn {
    padding: 0.5rem 1rem;
  }

  .videoDiv {
    display: none;
  }

  .socialLoginButton button {
    padding: 0.3rem;
    margin: 0.5rem;
  }

  .loginForm,
  .registerForm {
    width: 130%;
    gap: 0.5rem;
  }

  .socialLoginButton {
    padding: 0;
  }

  .foundAccount {
    margin-top: 1rem;
  }

  .showInSmall {
    display: block;
  }

  .loginFormDiv,
  .registerFormDiv {
    flex-basis: 60%;
  }
  .loginPage {
    box-shadow: none;
  }
  
}

@media (max-width: 550px) {
  .loginHeaderDiv h3 {
    font-size: 22px;
  }
  .loginContainer,
  .registerContainer {
    width: 85%;
  }
}