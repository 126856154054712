.loader {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: #18181b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader svg {
  width: 100%;
}

.loading-text {
  width: 100%;
  font-weight: bold;
  color: #fff;
  transform: translate(0.4rem);
  margin-top: 1rem;
  font-family: "Cafe24ClassicType-Regular";
}
