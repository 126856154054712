.search-container .goBackBtn {
  color: #9ca8b4;
  background-color: transparent;
  border: none;
  padding-left: 5rem;
  text-align: left;
  margin-bottom: 2rem;
  margin-top: -3rem;
}

.search-container .goBackBtn:focus-visible {
  box-shadow: none;
}

.search-container .goBackBtn:active {
  background-color: transparent !important;
}
.search-container .goBackBtn:hover {
  background-color: transparent;
}

.info-container {
  border: 1px solid rgb(68 64 60/1);
  border-radius: 8px;
}

.info-container h4 {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 800;
  color: #f2f5f7;
}

.info-text-muted {
  font-family: "Noto Serif KR", serif;
  font-size: 13px;
  color: #9ca8b4;
  line-height: 1.7;
  text-align: left;
  width: 80%;
}

.tip-info {
  font-family: "Noto Serif KR", serif;
  font-size: 13px;
  line-height: 1.5;
  color: #8c8c8c;
  margin-bottom: 1.3rem;
}

.tipsbtn {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "Figtree", sans-serif;
  height: 40px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 14px;
  margin-top: 1rem;
}

.tipsbtn:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.tipsbtn:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.tipsbtn:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

@media (max-width: 767px) {
  .bg-image {
    max-width: fit-content;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (max-width: 580px) {
  .info-container {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.5rem;
  }
}
