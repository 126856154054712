.card-box {
  padding-bottom: 70px;
  animation: fadeIn 0.5s ease-in-out;
}

.card-box li img:hover {
  transform: translateY(-5%);
}

ul.baraja-container li {
  background: none !important;
}
