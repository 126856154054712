.link-animation {
  width: fit-content;
  height: fit-content;
  display: flex;
  place-items: center center;
  background: #fff;
  color: #000;
  padding: 7px;
  padding-left: 14px;
  border-radius: 110px;
  animation: fadeIn 0.5s ease-in-out;
}

.link-animation .container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.link-animation .container .letter-container {
  font-size: 1.2rem;
  position: relative;
  width: 1.1rem;
  height: 2rem;
  overflow: hidden;
  font-weight: 700;
}

.link-animation .container .letter-container div {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  pointer-events: none;
}
