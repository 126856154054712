.error-page {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: #18181b;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error-header {
  font-size: 10vw;
  line-height: 1em;
  position: relative;
}
.error-header:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #71b7e6,
    #69a6ce,
    #b98acc,
    #ee8176,
    #b98acc,
    #69a6ce,
    #9b59b6
  );
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
  animation: animate 10s ease-in-out infinite;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.error-text {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 2em;
  max-width: 600px;
  position: relative;
  font-family: "Cafe24ClassicType-Regular";
}
.error-text:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-page .goBackBtn {
  color: #9ca8b4;
  background-color: transparent;
  border: none;
}

.error-page .goBackBtn:focus-visible {
  box-shadow: none;
}

.error-page .goBackBtn:active {
  background-color: transparent !important;
}
.error-page .goBackBtn:hover {
  background-color: transparent;
}
