.blog-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
  margin-bottom: 6rem;
}

.blog-post {
  border: 1px solid rgb(68 64 60/1);
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  text-align: start;
  max-width: 60rem;
  gap: 1rem;
}

.blog-post:hover {
  border: 1px solid #ffd11a;
}

.post-image {
  width: 100%;
  border-radius: 8px;
}

.post-title {
  margin-top: 15px;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.post-date {
  font-size: 0.9em;
  opacity: 0.75;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fff;
}

.post-summary {
  margin: 10px 0;
  color: #fff;
  opacity: 0.75;
}

.post-category {
  color: rgb(140 140 140/1);
  background-color: rgb(26 26 26/1);
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}

.read-more-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .post-title {
    font-size: 1.2rem;
  }
  .post-date {
    font-size: 0.9rem;
  }
  .post-summary {
    font-size: 0.9rem;
  }
}

@media (max-width: 690px) {
  .post-title {
    font-size: 1.1rem;
  }
  .post-date {
    font-size: 0.8rem;
  }
  .post-summary {
    font-size: 0.9rem;
  }
  .blog-post {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
