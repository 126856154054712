.question-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 10%;
  position: relative;
}

.question-icon {
  background-color: transparent;
  border: none;
  display: inline-block;
  color: #fff;
  font-size: 1.5rem;
}

.popover-header {
  font-family: "Noto Sans KR", sans-serif;
  color: #ff5000;
  background-color: #fff;
}

.popover-body {
  font-family: "Noto Serif KR", serif;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
