.forum-post-full-container {
  padding-top: 210px;
  padding-bottom: 80px;
  padding-left: 10rem;
  padding-right: 10rem;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  text-align: start;
}

.forum-post-back-container {
  display: flex;
  align-items: center;
}

.forum-post-full-container .goBackBtn {
  color: #9ca8b4;
  background-color: transparent;
  border: none;
  padding-left: 0;
}

.forum-post-full-container .goBackBtn:focus-visible {
  box-shadow: none;
}

.forum-post-full-container .goBackBtn:active {
  background-color: transparent !important;
}
.forum-post-full-container .goBackBtn:hover {
  background-color: transparent;
}

.forum-post-content-container {
  padding-top: 48px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  max-width: 1100px;
}

.forum-post-full-category {
  background-color: rgb(44, 44, 44);
  color: rgba(255, 255, 255, 0.75);
  border-radius: 9999px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}
.forum-post-full-header {
  padding-top: 48px;
  padding-bottom: 36px;
  display: flex;
  justify-content: center;
}
.forum-post-full-title {
  color: #f2f5f7;
  margin-top: 24px;
  margin-bottom: 16px;
}
.forum-post-full-author {
  color: rgb(140 140 140/1);
  font-size: 0.875rem;
  opacity: 0.75;
}
.forum-post-full-date {
  color: rgb(140 140 140/1);
  font-size: 0.875rem;
  opacity: 0.75;
}

.forum-post-full-icon {
  cursor: pointer;
  color: rgb(140 140 140/1);
  font-size: 0.875rem;
  opacity: 0.75;
}

.forum-post-full-comment-icon {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
}
