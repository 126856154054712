.new-blog-container .form-label {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 800;
  color: #9ca8b4;
}

.new-blog-container {
  max-width: 800px;
  display: inline-block;
  text-align: start;
  font-family: "Noto Serif KR", serif;
}

.new-blog-container input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  font-style: italic;
  left: 15px;
  pointer-events: none;
  right: 15px;
  font-size: 14px;
}
